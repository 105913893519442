import { useTranslation } from '@/app/i18n/client';
import { useAuth } from '@/hooks/auth';
import { ReferAFriendContentType } from '@/types/json-content/refer_a_friend';
import { processMarkdown } from '@/utils/helpers';
import { cn } from '@/utils/style';
import { Heading, Text } from '@purposeinplay/core-v2';
import React from 'react';
import LockSvg from 'public/assets/lock-raf.svg';

export const GuestMainHeading = () => {
  const { t } = useTranslation();
  const content = t('refer_a_friend') as ReferAFriendContentType;
  return (
    <div className="relative z-10 mb-spacing-lg pt-12 md:mb-20 md:pt-18">
      <div className="max-w-xs -space-y-2 leading-8 md:max-w-sm">
        <Heading
          as="h1"
          className="text-2xl font-extrabold uppercase text-text-default md:text-3xl"
        >
          <span
            dangerouslySetInnerHTML={{
              __html: processMarkdown(
                content?.guest_heading,
                undefined,
                true,
              ) as any,
            }}
          />
        </Heading>
      </div>
    </div>
  );
};

export const MainHeading = () => {
  const { t } = useTranslation();
  const content = t('refer_a_friend') as ReferAFriendContentType;

  const { user } = useAuth();

  return (
    <div
      className={cn([
        'relative z-10 mb-2.5 pt-18 md:mb-12 lg:pt-12',
        !user?.isAuthenticated && !user?.isLoading && `mb-0 lg:mb-0`,
      ])}
    >
      <Heading
        as="h1"
        className="text-xl font-black uppercase italic leading-8"
      >
        <span
          dangerouslySetInnerHTML={{
            __html: processMarkdown(
              content?.main_heading?.title,
              undefined,
              true,
            ) as any,
          }}
        />
      </Heading>
      <Heading
        as="h2"
        className="mb-2 max-w-[20rem] text-3xl font-extrabold uppercase leading-9 text-text-default"
      >
        <span
          dangerouslySetInnerHTML={{
            __html: processMarkdown(
              content?.main_heading?.subtitle,
              undefined,
              true,
            ) as any,
          }}
        />
      </Heading>
      <Text size="sm" className="font-semibold text-base-primary">
        {content?.main_heading?.description}
      </Text>
    </div>
  );
};

export const MainHeadingRestricted = ({
  className,
  titleStyle,
  subtitleStyle,
}: {
  className?: string;
  titleStyle?: string;
  subtitleStyle?: string;
}) => {
  const { t } = useTranslation();
  const content = t('refer_a_friend') as ReferAFriendContentType;
  return (
    <div
      className={cn([
        'relative z-10 flex flex-col gap-1 pt-18 lg:pt-12',
        className && className,
      ])}
    >
      <div className="flex flex-row items-center gap-1">
        <LockSvg />
        <Text size="sm" className="font-medium uppercase text-icons-warning">
          {content?.main_heading_not_eligible?.description}
        </Text>
      </div>

      <Heading
        as="h2"
        className={cn([
          'max-w-[20rem] text-3xl font-extrabold text-text-default',
          titleStyle && titleStyle,
        ])}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: processMarkdown(
              content?.main_heading_not_eligible?.title,
              undefined,
              true,
            ) as any,
          }}
        />
      </Heading>
      <Text
        className={cn([
          'mb-3 max-w-[20rem] text-2xl font-normal text-text-default md:mb-0',
          subtitleStyle && subtitleStyle,
        ])}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: processMarkdown(
              content?.main_heading_not_eligible?.subtitle,
              undefined,
              true,
            ) as any,
          }}
        />
      </Text>
    </div>
  );
};
