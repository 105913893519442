import { Currency } from '@/types/currency';
// @ts-ignore
import { formatMoney as format } from 'accounting';
import client from './api';

export const getPrecision = (subunitsToUnit: number): number => {
  return Math.log(subunitsToUnit) / Math.log(10);
};

export const subunitsToUnit: any = {
  EUR: 100,
  USD: 100,
  BTC: 100000000,
  BCH: 100000000,
  ETH: 1000000000,
  LTC: 100000000,
  DOG: 100000000,
  USDT: 100000000,
  XRP: 1000000,
  ADA: 1000000,
  TRX: 1000000,
  BNB: 100000000,
};

const emptyAmount = (currency: string) => {
  return format(0, {
    symbol: currency,
    format: '%v %s',
    thousand: '',
    precision: getPrecision(subunitsToUnit[currency]),
  });
};

export const formatMoney = (
  value: any,
  currency: string,
  precision?: number,
) => {
  if (currency == '') {
    return emptyAmount(currency);
  }

  if (value === 0) {
    return emptyAmount(currency);
  }

  let amount = value / subunitsToUnit[currency];

  if (!amount) {
    return emptyAmount(currency);
  }

  return format(amount, {
    symbol: currency,
    format: '%v %s',
    thousand: '',
    precision: precision ? precision : getPrecision(subunitsToUnit[currency]),
  });
};

export function countTrailingZeroes(number: Number) {
  const strNumber = number.toString();
  const regex = /0*$/;
  const match = strNumber.match(regex);
  return match ? match[0].length : 0;
}

export function calculateBalance(currency: Currency) {
  const subunits = currency.subunits_to_unit;
  const numberOfZeroes = countTrailingZeroes(subunits);
  const zeroesString = '0'.repeat(numberOfZeroes);
  return `0.${zeroesString} ${currency.code}`;
}

/**
 *
 * @param payload of type {[key]: number} where number is amount_cents
 * @returns object with converted cents to USD
 */
export const getFiatFromCents = async (payload: {}) => {
  const ratesPromise = client('info/crypto_exchange_rates').then((data) => {
    const usdRates = data['USD'];
    const results: any[] = [];
    Object.keys(usdRates).map((currency) =>
      results.push({
        ...usdRates[currency],
        symbol: currency,
        quote: {
          USD: {
            price: usdRates[currency].total_exchange_rate,
          },
        },
      }),
    );
    return results;
  });

  const currenciesPromise = client('info/currencies');

  const [rates, currencies] = await Promise.all([
    ratesPromise,
    currenciesPromise,
  ]);

  const returnObj = Object.keys(payload)?.map((key) => {
    const foundCrypto = rates?.find(
      (crypto: any) => crypto.symbol?.toLowerCase() === key?.toLowerCase(),
    );
    const foundCurrency = currencies.find(
      (currencyItem: any) =>
        currencyItem.code?.toLowerCase() === key?.toLowerCase(),
    );
    if (!foundCrypto || !foundCurrency) return;

    const price = foundCrypto?.quote.USD.price;
    // @ts-ignore
    const amount = (payload[key] as number) / foundCurrency.subunits_to_unit;
    return {
      [key]: price * amount,
    };
  });
  return returnObj;
};
