import { Button, IconButton } from '@purposeinplay/core-v2';
import React from 'react';
//@ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useToast } from '@purposeinplay/utils';
import { RAFCode, useClaimRef } from '@/hooks/raf';
import { useTranslation } from '@/app/i18n/client';
import { ReferAFriendContentType } from '@/types/json-content/refer_a_friend';
import EmptyState from '../EmptyState';
import ClaimedAmountCell from './claimed-amount-cell';
import ClaimButton from './claim-button';
import config from '@/config';

const MobileTable = ({
  codes,
  isLoading,
  currency,
  ...props
}: {
  currency: string;
  codes: RAFCode[];
  isLoading: boolean;
}) => {
  const { mutateAsync: claimRefCode, isPending: claimRefCodeLoading } =
    useClaimRef();

  const { toast } = useToast();

  const { t } = useTranslation();
  const content = t('refer_a_friend') as ReferAFriendContentType;

  if (!isLoading && codes?.length === 0) {
    return (
      <EmptyState
        title={content?.raf_table?.no_refs?.title}
        description={content?.raf_table?.no_refs?.description}
      />
    );
  }
  return (
    <div className="w-full space-y-2.5 md:hidden">
      {codes?.map((code) => {
        return (
          <div
            className="w-full rounded-lg bg-surface-subdued p-3"
            key={`mobile-${code.refcode}`}
          >
            <div className="mb-spacing-md grid grid-cols-2">
              <div className="flex w-full flex-col space-y-spacing-sm text-xs uppercase text-text-subdued">
                {content?.raf_table?.headers?.map(
                  (header, index) =>
                    index !== content?.raf_table?.headers?.length - 1 && (
                      <span key={header}>{header}</span>
                    ),
                )}
              </div>
              <div className="flex w-full flex-col space-y-spacing-sm text-end text-xs text-text-default">
                <CopyToClipboard
                  text={`${config.SITE_URL}/?ref_code=${code?.refcode}`}
                  onCopy={() => {
                    toast({
                      state: 'success',
                      title: 'Copied',
                    });
                  }}
                >
                  <div className="flex flex-row items-center justify-end gap-3 hover:cursor-pointer">
                    <span className="font-bold text-text-default">
                      {code?.refcode}
                    </span>
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 fill-current"
                    >
                      <path d="M17.1251 1.04431H6.12508C5.11675 1.04431 4.29175 1.86931 4.29175 2.87765V15.711H6.12508V2.87765H17.1251V1.04431ZM19.8751 4.71098H9.79175C8.78342 4.71098 7.95842 5.53598 7.95842 6.54431V19.3776C7.95842 20.386 8.78342 21.211 9.79175 21.211H19.8751C20.8834 21.211 21.7084 20.386 21.7084 19.3776V6.54431C21.7084 5.53598 20.8834 4.71098 19.8751 4.71098ZM19.8751 19.3776H9.79175V6.54431H19.8751V19.3776Z" />
                    </svg>
                  </div>
                </CopyToClipboard>
                <span className="flex flex-row items-center justify-end">
                  <ClaimedAmountCell
                    currency={currency}
                    claimed_amount={code?.claimed_amount}
                  />
                </span>
                <span>{code?.users_count}</span>
              </div>
            </div>
            <Button
              size="sm"
              className="mx-auto w-full"
              color="primary"
              disabled={
                code?.to_claim_amount === 0 || claimRefCodeLoading
                  ? true
                  : false
              }
              isLoading={claimRefCodeLoading}
              onClick={async () => {
                try {
                  await claimRefCode({
                    ref_code: code?.refcode,
                  });
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              <>
                {content?.raf_table?.btn_label}
                <ClaimButton
                  currency={currency}
                  claimed_amount={code?.to_claim_amount}
                  className="ml-1"
                />
              </>
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default MobileTable;
