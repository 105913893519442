import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import client from '@/utils/api';
import { useAuth } from './auth';
import { useProjectInfo } from './info';
import { useToast } from '@purposeinplay/utils';
import { useMemo } from 'react';
import { useAnalytics } from '@/hooks/use-analytics';

export type RAFCode = {
  refcode: string;
  claimed_amount: number;
  users_count: number;
  completed_users_count: number;
  to_claim_amount: number;
};
export type RAFList = {
  referral_codes: RAFCode[];
  aggregated_data: {
    total_invited: number;
    total_claimed: number;
  };
  currency: string;
};
const useRAFList = (enabled = true): UseQueryResult<RAFList> => {
  const { user } = useAuth();
  // const { data } = useProjectInfo();
  const { toast } = useToast();
  // const hasRafAvailable = data?.available_referral_system === true;
  return useQuery({
    queryKey: ['rafList', user?.currentUser],
    queryFn: async () => {
      try {
        const res = await client(
          `player/referral_system/list?currency=${user?.currentUser?.currency}`,
          undefined,
          'GET',
          {
            'Content-Type': 'application/json',
          },
        );
        return res;
      } catch (e) {
        toast({
          state: 'error',
          title: 'Could not fetch referral system list.',
        });
        throw e;
      }
    },
    retry: false,
    enabled:
      user?.isAuthenticated && !user?.isLoading && enabled ? true : false,
  });
};
const useCreateRefCode = () => {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const { track } = useAnalytics();
  return useMutation({
    mutationKey: ['createRefCode'],
    mutationFn: async (data: { ref_code: string }) => {
      try {
        const res = await client(
          'player/referral_system',
          {
            ref_code: data?.ref_code,
          },
          'POST',
        );
        toast({
          state: 'success',
          title: 'Referral code created successfully.',
        });
        track('referral_code_created', {
          category: 'referral',
          label: data?.ref_code,
        });

        return res;
      } catch (e) {
        toast({
          state: 'error',
          title: 'Could not fetch referral system list.',
        });
        throw e;
      }
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['rafList'] });
    },
  });
};
const useClaimRef = () => {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const { track } = useAnalytics();
  return useMutation({
    mutationKey: ['claimRef'],
    mutationFn: async (data: { ref_code: string }) => {
      try {
        const res = await client(
          'player/referral_system/claim',
          {
            ref_code: data?.ref_code,
          },
          'POST',
        );
        track('referral_code_claimed', {
          category: 'referral',
          label: data?.ref_code,
        });

        toast({
          state: 'success',
          title: 'Referral code bonus claimed successfully.',
        });
        return res;
      } catch (e: any) {
        switch (e?.errors?.ref_code) {
          case 'invalid':
            toast({
              state: 'error',
              title: 'Invalid referral code',
            });
            break;
          case 'already_claimed':
            toast({
              state: 'error',
              title: 'Already claimed',
            });
            break;
          default:
            toast({
              state: 'error',
              title:
                'Could not claim rewards. Please try again or contact Customer Support.',
            });
        }
        throw e;
      }
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['rafList'] });
    },
  });
};
const useRefCode = (code: string): UseQueryResult<any> => {
  return useQuery({
    queryKey: ['getRefCode', code],
    queryFn: () =>
      client(`/info/locales?ref_code=${code}`, undefined, 'GET', {
        Accept: 'application/vnd.s.v1+json',
        'Content-Type': 'application/json',
      }),
    enabled: false,
  });
};

const useIsRafEligible = () => {
  const { user } = useAuth();
  const isRafEligible = useMemo(() => {
    return user?.currentUser?.statuses?.find(
      (status: { name: string; id: string }) => status.id === 'raf:eligible',
    );
  }, [user?.currentUser?.statuses]);

  return isRafEligible;
};

export {
  useRAFList,
  useCreateRefCode,
  useClaimRef,
  useRefCode,
  useIsRafEligible,
};
