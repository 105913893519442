import React from 'react';
import RafStatCard from './stat-card';
import { getIconByCurrency } from '@/utils/helpers';
import { useAppSelector } from '@/hooks/redux';
import { useFiatFromCrypto } from '@/hooks/fiat';

type Props = {
  total_claimed: number;
  currency: string;
};

const TotalClaimed: React.FC<Props> = ({ total_claimed, currency }) => {
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);

  const fiatAmount = useFiatFromCrypto(currency, total_claimed);
  return (
    <RafStatCard
      title="Total Claimed"
      value={viewFiat ? fiatAmount : total_claimed}
      icon={
        <span className="ml-1 flex h-4 w-4 items-center justify-center">
          {getIconByCurrency(currency?.toLowerCase())}
        </span>
      }
    />
  );
};

export default TotalClaimed;
