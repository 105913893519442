import { useCurrencyObj } from '@/hooks/currencies';
import { useFiatFromCrypto } from '@/hooks/fiat';
import { useAppSelector } from '@/hooks/redux';
import { getIconByCurrency } from '@/utils/helpers';
import { cn } from '@/utils/style';
import React from 'react';

type Props = {
  claimed_amount: number;
  currency: string;
  className?: string;
};

const ClaimButton: React.FC<Props> = ({
  claimed_amount,
  currency,
  className,
}) => {
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);

  const fiatAmount = useFiatFromCrypto(currency, claimed_amount);

  return (
    <span
      className={cn(['flex flex-row items-center', className && className])}
    >
      {!viewFiat ? claimed_amount : fiatAmount}
      <span className="ml-1">{getIconByCurrency(currency?.toLowerCase())}</span>
    </span>
  );
};

export default ClaimButton;
