import { Text } from '@purposeinplay/core-v2';
import React from 'react';

type Props = {
  title: string;
  icon?: React.ReactNode;
  value: any;
};

const RafStatCard: React.FC<Props> = ({ title, icon, value }) => {
  return (
    <div className="flex flex-col space-y-2.5 rounded-2xl bg-bgr-lightest p-2.5">
      <Text
        size="sm"
        className="flex flex-row items-center font-semibold text-text-subdued"
      >
        {title} {icon}
      </Text>
      <Text size="lg" className="font-bold text-text-subdued">
        {value}
      </Text>
    </div>
  );
};

export default RafStatCard;
