import React, { useState } from 'react';
import { FieldGroup, IconButton, Input, Tooltip } from '@purposeinplay/core-v2';
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useAuth } from '@/hooks/auth';
import { useCreateRefCode, useIsRafEligible } from '@/hooks/raf';
import { generateReferralCode } from '@/utils/helpers';
import LinkSVG from 'public/assets/link.svg';
import { useTranslation } from '@/app/i18n/client';
import { ReferAFriendContentType } from '@/types/json-content/refer_a_friend';
import RafStatCard from './stat-card';
import { DivLoader } from '../Loaders';
import TotalClaimed from './total-claimed';
import { cn } from '@/utils/style';
import config from '@/config';

const RafInfo = ({
  has50deposit,
  playerStatsLoading,
  rafList,
  rafListLoading,
  className,
}: {
  has50deposit: boolean;
  playerStatsLoading: boolean;
  rafList?: any;
  rafListLoading?: boolean;
  className?: string;
}) => {
  const { t } = useTranslation();
  const content = t('refer_a_friend') as ReferAFriendContentType;

  const { user } = useAuth();

  const [code, setCode] = useState(generateReferralCode(10));
  const [copied, setCopied] = useState(false);
  const {
    mutateAsync: createRefCode,
    isPending: createRefCodeLoading,
    error,
  } = useCreateRefCode();

  const isRafEligible = useIsRafEligible();

  return (
    <div className={cn([className && className])}>
      {has50deposit && !playerStatsLoading && (
        <div className="w-full">
          {user?.isAuthenticated && (
            <>
              <form
                className="relative z-10"
                onSubmit={async (e) => {
                  e.preventDefault();
                  try {
                    await createRefCode({
                      ref_code: code,
                    });
                    setCode(generateReferralCode(10));
                  } catch (e) {
                    console.log(e);
                  }
                }}
              >
                <FieldGroup className="mb-2.5 flex w-full flex-col !space-y-1 md:flex-row md:items-end md:space-y-0 md:[&>div]:w-full">
                  <Input
                    maxLength={10}
                    id="raf-code"
                    variant="static"
                    // @ts-ignore
                    error={error && error?.errors?.ref_code}
                    iconStyles="pointer-events-auto"
                    value={
                      isRafEligible
                        ? code
                        : content?.raf_table?.banned_ref?.no_code
                    }
                    leadingIcon={
                      <LinkSVG className="h-5 w-5 fill-current text-icons-subdued" />
                    }
                    trailingIcon={
                      isRafEligible && (
                        <Tooltip
                          styleOptions={{
                            arrowStyles: '!z-[99999]',
                            popperStyles: '!z-[99999]',
                          }}
                          trigger={
                            <CopyToClipboard
                              text={`${config.SITE_URL}/?ref_code=${code}`}
                            >
                              <IconButton
                                disabled={
                                  !isRafEligible ||
                                  createRefCodeLoading ||
                                  (rafList?.referral_codes &&
                                    rafList?.referral_codes?.length >= 10)
                                }
                                isLoading={createRefCodeLoading}
                                type="submit"
                                onClick={() => {
                                  setCopied(true);
                                }}
                                onMouseEnter={() => setCopied(false)}
                                size="sm"
                                color="tertiary"
                                iconOnly
                                ghost
                                className="!p-0"
                                iconLeft={
                                  <svg
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="fill-current"
                                  >
                                    <path d="M17.1251 1.04431H6.12508C5.11675 1.04431 4.29175 1.86931 4.29175 2.87765V15.711H6.12508V2.87765H17.1251V1.04431ZM19.8751 4.71098H9.79175C8.78342 4.71098 7.95842 5.53598 7.95842 6.54431V19.3776C7.95842 20.386 8.78342 21.211 9.79175 21.211H19.8751C20.8834 21.211 21.7084 20.386 21.7084 19.3776V6.54431C21.7084 5.53598 20.8834 4.71098 19.8751 4.71098ZM19.8751 19.3776H9.79175V6.54431H19.8751V19.3776Z" />
                                  </svg>
                                }
                              />
                            </CopyToClipboard>
                          }
                          placement={'top'}
                          displayOn={'hover'}
                        >
                          {rafList?.referral_codes &&
                          rafList?.referral_codes?.length >= 10
                            ? content?.tooltip_primary_btn?.disable
                            : copied
                              ? content?.tooltip_primary_btn?.copied
                              : content?.tooltip_primary_btn?.copy}
                        </Tooltip>
                      )
                    }
                    onChange={(e) => setCode(e.target.value)}
                    disabled
                    className="text-text-subdued"
                  />
                </FieldGroup>
              </form>
              <div className="relative z-10 grid grid-cols-2 gap-2.5">
                {rafListLoading ? (
                  <DivLoader className="h-18 w-full rounded-lg lg:w-full" />
                ) : (
                  <RafStatCard
                    title="Total Invited"
                    value={
                      rafList?.aggregated_data?.total_invited
                        ? rafList?.aggregated_data?.total_invited?.toString()
                        : '0'
                    }
                  />
                )}
                {rafListLoading ? (
                  <DivLoader className="h-18 w-full rounded-lg" />
                ) : (
                  <TotalClaimed
                    currency={rafList?.currency.toLocaleUpperCase() || 'BTC'}
                    total_claimed={
                      rafList?.aggregated_data?.total_claimed
                        ? rafList?.aggregated_data?.total_claimed
                        : 0
                    }
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default RafInfo;
