import React, { useState } from 'react';
import MobileTable from './mobile-table';
import { RAFCode, useClaimRef, useIsRafEligible } from '@/hooks/raf';
import { useWindowSize } from 'usehooks-ts';
import {
  Button,
  IconButton,
  Table,
  TableCell,
  TableRow,
  Text,
  Tooltip,
} from '@purposeinplay/core-v2';
import { DivLoader, TableLoaderV2 } from '../Loaders';
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from '@/app/i18n/client';
import { ReferAFriendContentType } from '@/types/json-content/refer_a_friend';
import EmptyState from '../EmptyState';
import ClaimedAmountCell from './claimed-amount-cell';
import ClaimButton from './claim-button';
import config from '@/config';

const RafTable = ({
  codes,
  isLoading,
  currency,
  ...props
}: {
  currency: string;
  codes: RAFCode[];
  isLoading: boolean;
}) => {
  const { width } = useWindowSize();
  const isMobile = width < 768;
  const [copied, setCopied] = useState(false);
  const platform = isMobile ? 'mobile' : 'desktop';
  const { mutateAsync: claimRefCode, isPending: claimRefCodeLoading } =
    useClaimRef();

  const isRafEligible = useIsRafEligible();

  const { t } = useTranslation();
  const content = t('refer_a_friend') as ReferAFriendContentType;

  if (!isLoading && !isRafEligible) {
    return (
      <EmptyState
        title={content?.raf_table?.banned_ref?.title}
        description={content?.raf_table?.banned_ref?.description}
        iconName="referrals"
      />
    );
  }

  if (!isLoading && (codes?.length === 0 || !codes)) {
    return (
      <EmptyState
        title={content?.raf_table?.no_refs?.title}
        description={content?.raf_table?.no_refs?.description}
        iconName="referrals"
      />
    );
  }

  return (
    <div className="relative flex w-full flex-col">
      {isLoading ? (
        <TableLoaderV2 headers={3} />
      ) : platform === 'desktop' ? (
        <Table
          {...props}
          headers={content?.raf_table?.headers}
          stylingOptions={{
            tableHeadCellStyles:
              'text-text-subdued uppercase font-medium first-of-type:pl-3 last:text-left last:w-40',
            tableStyles: ' w-full table-fixed text-xs',
          }}
        >
          {codes?.map((code, index: number) => {
            return (
              <TableRow
                key={`${code?.refcode}-${index}`}
                className="rounded-lg p-0"
              >
                <TableCell>
                  <div className="relative flex cursor-pointer flex-row items-center justify-between">
                    <Text
                      size="sm"
                      className="max-w-[20rem] truncate text-text-default"
                    >
                      {code?.refcode}
                    </Text>
                    <Tooltip
                      styleOptions={{
                        arrowStyles: '!z-[99999]',
                        popperStyles: '!z-[99999]',
                      }}
                      trigger={
                        <CopyToClipboard
                          text={`${config.SITE_URL}/?ref_code=${code?.refcode}`}
                        >
                          <IconButton
                            onClick={() => {
                              setCopied(true);
                            }}
                            onMouseEnter={() => setCopied(false)}
                            size="sm"
                            color="tertiary"
                            iconOnly
                            ghost
                            className="!p-0"
                            iconLeft={
                              <svg
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="fill-current"
                              >
                                <path d="M17.1251 1.04431H6.12508C5.11675 1.04431 4.29175 1.86931 4.29175 2.87765V15.711H6.12508V2.87765H17.1251V1.04431ZM19.8751 4.71098H9.79175C8.78342 4.71098 7.95842 5.53598 7.95842 6.54431V19.3776C7.95842 20.386 8.78342 21.211 9.79175 21.211H19.8751C20.8834 21.211 21.7084 20.386 21.7084 19.3776V6.54431C21.7084 5.53598 20.8834 4.71098 19.8751 4.71098ZM19.8751 19.3776H9.79175V6.54431H19.8751V19.3776Z" />
                              </svg>
                            }
                          />
                        </CopyToClipboard>
                      }
                      placement={'top'}
                      displayOn={'hover'}
                    >
                      {copied
                        ? content?.raf_table?.copy_tooltip_label?.copied
                        : content?.raf_table?.copy_tooltip_label?.copy}
                    </Tooltip>
                  </div>
                </TableCell>
                <TableCell className="font-bold">
                  <ClaimedAmountCell
                    claimed_amount={code?.claimed_amount}
                    currency={currency}
                  />
                </TableCell>

                <TableCell className="font-bold">{code?.users_count}</TableCell>
                <TableCell className="rounded-r-lg font-bold">
                  <Button
                    size="xs"
                    color="primary"
                    className="mx-auto w-full"
                    disabled={
                      code?.to_claim_amount === 0 || claimRefCodeLoading
                        ? true
                        : false
                    }
                    isLoading={claimRefCodeLoading}
                    onClick={async () => {
                      try {
                        await claimRefCode({
                          ref_code: code?.refcode,
                        });
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                  >
                    <div className="flex flex-row items-center">
                      {content?.raf_table?.btn_label}
                      <ClaimButton
                        currency={currency}
                        claimed_amount={code?.to_claim_amount}
                        className="ml-1"
                      />
                    </div>
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      ) : (
        <MobileTable codes={codes} isLoading={isLoading} currency={currency} />
      )}
    </div>
  );
};

export default RafTable;
