import { useFiatFromCrypto } from '@/hooks/fiat';
import { useAppSelector } from '@/hooks/redux';
import { getIconByCurrency } from '@/utils/helpers';
import React from 'react';

type Props = {
  claimed_amount: number;
  currency: string;
};

const ClaimedAmountCell: React.FC<Props> = ({ claimed_amount, currency }) => {
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);

  const fiatAmount = useFiatFromCrypto(currency, claimed_amount);

  return (
    <span className="flex flex-row items-center">
      {!viewFiat ? claimed_amount : fiatAmount}
      <span className="ml-1">{getIconByCurrency(currency?.toLowerCase())}</span>
    </span>
  );
};

export default ClaimedAmountCell;
